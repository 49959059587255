import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { addUnionpaySetting, fetchUnionpayChargingList, updateUnionpaySetting, fetchUnionpaySettingDetail } from "@/api/sale";
import store from "@/store";
import "@/utils/global.js";
export default {
  name: "edit",
  data: function data() {
    var self = this;
    return {
      // 查询字段
      editForm: {
        name: '',
        charging_id: '',
        duration: '',
        price: '',
        min_cost: 1,
        activity_time: '',
        status: true
      },
      id: '',
      rules: {
        name: [{
          required: true,
          message: "请输入活动名称"
        }],
        charging_id: [{
          required: true,
          message: "请选择充电桩"
        }],
        duration: [{
          required: true,
          message: "充电时长不能为空"
        }],
        price: [{
          required: true,
          message: "价格不能为空"
        }],
        min_cost: [{
          required: true,
          message: "最低消费不能为空"
        }]
      },
      uploadOption: {
        uploadUrl: process.env.VUE_APP_URL + "/advert/wechat/slide/upload",
        uploadHeader: {
          Authorization: "bearer " + store.getters.token
        },
        uploadName: "file",
        fileList: [],
        limit: 1
      },
      //
      editStatus: "add",
      chargingOptions: [],
      chargingLoading: false
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    getUnionpaySettingDetail: function getUnionpaySettingDetail(id) {
      var _this = this;

      fetchUnionpaySettingDetail(id).then(function (res) {
        var data = res.data;

        _this.resetEditForm(data);

        var property = data["property"];
        _this.villageOptions = [];

        if (property) {
          _this.villageOptions.push(res.data.property);
        }

        _this.$nextTick(function () {
          _this.$refs["editForm"].clearValidate();
        });
      }).catch(function (error) {
        console.log(error);
      });
    },
    init: function init() {
      if (this.$route.query && this.$route.query.idArr) {
        console.log(this.$route.query && this.$route.query.idArr);
        this.editStatus = "update";
        var id = this.$route.query.id;
        var arr = this.$route.query && this.$route.query.idArr;
        this.totalPage = arr.length;
        this.idArr = arr;
        this.idIndex = arr.indexOf(String(id));
        this.searchForm = this.$route.query.searchForm;
        console.log(this.idIndex, "index ");
        this.id = id;
        this.getUnionpaySettingDetail(id);
      }
    },
    resetEditForm: function resetEditForm(data) {
      this.editForm = {
        name: data.name,
        charging_id: data.charging_id,
        duration: data.duration,
        price: data.price,
        min_cost: data.min_cost,
        activity_time: [data.start_time, data.end_time],
        status: data.status == 1 ? true : false
      };
      this.remoteMethod(data.charging_number);
    },
    handleAdd: function handleAdd() {
      var _this2 = this;

      console.log("添加活动充电桩");
      this.$refs["editForm"].validate(function (valid) {
        if (valid) {
          addUnionpaySetting(_this2.editForm).then(function (response) {
            _this2.$message({
              type: "success",
              message: "添加活动充电桩成功"
            });

            console.log("跳转");

            _this2.$router.push("/sale/unionpay/setting");
          }).catch(function (err) {
            console.log(err);

            _this2.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleUpdate: function handleUpdate() {
      var _this3 = this;

      console.log("修改活动充电桩", this.editForm);
      this.$refs["editForm"].validate(function (valid) {
        if (valid) {
          updateUnionpaySetting(_this3.id, _this3.editForm).then(function (response) {
            _this3.$message({
              type: "success",
              message: "修改活动充电桩成功"
            });

            console.log("跳转");

            _this3.$router.push("/sale/unionpay/setting");
          }).catch(function (err) {
            console.log(err);

            _this3.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.editForm["activity_time"][0] = this.$moment(val[0]).format('YYYY-MM-DD HH:mm:ss');
        this.editForm["activity_time"][1] = this.$moment(val[1]).format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.editForm["activity_time"][0] = "";
        this.editForm["activity_time"][1] = "";
      }
    },
    remoteMethod: function remoteMethod(query) {
      var _this4 = this;

      console.log(query);

      if (query !== "") {
        this.chargingLoading = true;
        setTimeout(function () {
          var data = {
            keyword: query
          };
          fetchUnionpayChargingList(data).then(function (res) {
            console.log(res);
            _this4.chargingLoading = false;
            _this4.chargingOptions = res.data;
          }).catch(function (err) {
            _this4.chargingLoading = false;
          });
        }, 100);
      } else {
        this.chargingOptions = [];
      }
    },
    goBack: function goBack() {
      this.$router.push({
        name: "unionpaySettingList",
        params: {
          searchForm: this.searchForm
        }
      });
    }
  }
};