import request from '@/utils/request';
/**
 *  fetch: 获取
 *  update: 修改
 *  add: 增加
 *  del: 删除
 */
// UnionpaySetting 活动设置
// 活动列表

export function fetchUnionpaySettingList(data) {
  return request({
    url: '/sale/unionpay/setting',
    method: 'get',
    params: data
  });
}
export function fetchUnionpayChargingList(data) {
  return request({
    url: '/sale/unionpay/charging_list',
    method: 'get',
    params: data
  });
} // 添加活动设置

export function addUnionpaySetting(data) {
  return request({
    url: '/sale/unionpay/setting',
    method: 'post',
    data: data
  });
} // 活动设置编辑

export function updateUnionpaySetting(id, data) {
  return request({
    url: '/sale/unionpay/setting/' + id,
    method: 'put',
    data: data
  });
} // 活动设置编辑

export function fetchUnionpaySettingDetail(id) {
  return request({
    url: '/sale/unionpay/setting/' + id,
    method: 'get'
  });
} // 删除活动设置

export function delUnionpaySetting(data) {
  return request({
    url: '/sale/unionpay/setting',
    method: 'delete',
    data: data
  });
} // 交易记录列表

export function fetchUnionpayPaylogList(data) {
  return request({
    url: '/sale/unionpay/paylog',
    method: 'get',
    params: data
  });
} // 联通秒行卡订单记录列表

export function fetchYpsubList(data) {
  return request({
    url: '/sale/ypsub/list',
    method: 'get',
    params: data
  });
} //重推

export function rePushYpsub(id) {
  return request({
    url: '/sale/ypsub/rePush/' + id,
    method: "put"
  });
}