var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { position: "relative" } },
    [
      _c(
        "el-form",
        {
          ref: "editForm",
          staticClass: "edit-form search_form",
          attrs: {
            model: _vm.editForm,
            "label-position": "right",
            "label-width": "130px",
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "活动名称 :", prop: "name" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入活动名称", clearable: "" },
                model: {
                  value: _vm.editForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "name", $$v)
                  },
                  expression: "editForm.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "选择充电桩 :", prop: "charging_id" }
            },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "请输入设备编号",
                    "remote-method": _vm.remoteMethod,
                    clearable: "",
                    loading: _vm.chargingLoading
                  },
                  model: {
                    value: _vm.editForm.charging_id,
                    callback: function($$v) {
                      _vm.$set(_vm.editForm, "charging_id", $$v)
                    },
                    expression: "editForm.charging_id"
                  }
                },
                _vm._l(_vm.chargingOptions, function(item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { label: item.name, value: item.id }
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v("[" + _vm._s(item.id) + "] " + _vm._s(item.name))
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#8492a6",
                            "font-size": "13px"
                          }
                        },
                        [_vm._v(_vm._s(item.number))]
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "充电时长 :", prop: "duration" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "充电时长", clearable: "" },
                model: {
                  value: _vm.editForm.duration,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "duration", $$v)
                  },
                  expression: "editForm.duration"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "价格 :", prop: "price" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "价格", clearable: "" },
                model: {
                  value: _vm.editForm.price,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "price", $$v)
                  },
                  expression: "editForm.price"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "block-item",
              attrs: { label: "最低消费：", prop: "min_cost", required: "" }
            },
            [
              _c(
                "el-input",
                {
                  attrs: { type: "number" },
                  model: {
                    value: _vm.editForm.min_cost,
                    callback: function($$v) {
                      _vm.$set(_vm.editForm, "min_cost", $$v)
                    },
                    expression: "editForm.min_cost"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "block-item", attrs: { label: "活动时间 :" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetimerange",
                  "start-placeholder": "活动开始日期",
                  "end-placeholder": "活动结束日期"
                },
                on: { change: _vm.handleTime },
                model: {
                  value: _vm.editForm.activity_time,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "activity_time", $$v)
                  },
                  expression: "editForm.activity_time"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "block-item", attrs: { label: "状态 :" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.editForm.status,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "status", $$v)
                  },
                  expression: "editForm.status"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "footer-container",
          staticStyle: { "margin-top": "30px" }
        },
        [
          _vm.editStatus === "add"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: false, size: "medium" },
                  on: { click: _vm.handleAdd }
                },
                [_vm._v("添加")]
              )
            : _vm._e(),
          _vm.editStatus === "update"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: false, size: "medium" },
                  on: { click: _vm.handleUpdate }
                },
                [_vm._v("修改")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { size: "medium" }, on: { click: _vm.goBack } },
            [_c("span", [_vm._v("取消")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }